.project_pool {
	position:relative;
	overflow: hidden;
	padding: 100px 30px 40px 30px;

	.project-container {
		@include row;
		padding:0 5px;
		width: 33.333%;
		float: left;
	}

	.tile {
		position:relative;
		margin-bottom:10px;
		float:left;
		@include transform(translateY(100px));
		opacity:0;
		min-height:200px;
		@include transition(.4s transform $ease-out-quint,opacity .4s linear);

		&.show {
			@include transform(translateY(0px));
			opacity:1;
		}

		&:hover {
			opacity:1;
			.hoverlay {
			    opacity: 1
			}			
		}

		.hoverlay {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    background-color: rgba(0, 0, 0, 0.98);
		    opacity: 0;
		    border: 1px solid white;
			@include transition(opacity .2s);
			.hoverlay_table {
				display: table;
				height: 100%;
				width: 100%
			}			
			.hoverlay_content {
			    display: table-cell;
			    vertical-align: middle;
			    text-align: center;
			    padding: 0 20px
			}			
		}

		.title {
			@include transform-origin(bottom left);
			@include transform(rotate(-90deg));
			position:absolute;
			bottom:0px;
			font-size:$metaSize;
			line-height:$metaSizeLH;
			left:-4px;
			color:$black;
		}
		.image {
			line-height:0px;
			min-height:200px;

			img {
				opacity:0;
				@include transition(opacity .2s);
			}
		}

		&.img-ready .image {
			img {
				opacity:1;
			}
		}

		// &.tile-0 {
		// 	@include span-columns(4);
		// 	@include shift(4);
		// }
		// &.tile-1 {
		// 	@include span-columns(4);
		// 	@include shift(0);
		// }
		// &.tile-2 {
		// 	@include span-columns(5);
		// 	@include shift(3);
		// }
		// &.tile-3 {
		// 	@include span-columns(4);
		// 	@include shift(2);
		// }
		// &.tile-4 {
		// 	@include span-columns(5);
		// 	@include shift(5);
		// }

		&.clone {
			position:fixed;
			margin:0px;
			z-index:9999;

			.image {
				background-size:contain;
				background-color:black;
				position:absolute;
				width:100%;
				height:100%;
				top:0px;
				left:0px;
				background-position:center;
				background-repeat:no-repeat;
			}
		}
	}

	.clone-bg {
		position:fixed;
		top:0px;
		left:0px;
		background-color:transparent;
		width:100%;
		height:100%;
		@include transition(background-color .5s);
		z-index:8000;

		&.transition {
			background-color:$black;
		}
	}
}

.back-to {
	text-align:center;
	padding-top:160px;
	width: 100%;
	text-align: center;

	a {
		color:$grey;
		font-size:$bodySize;
		line-height:$bodySizeLH;
	}
}

@include media($tablet) {
	.project_pool {
		padding-top:65px;

		// .tile {

		// 	&.tile-0 ,&.tile-1 ,&.tile-2 ,&.tile-3 ,&.tile-4 {
		// 		@include span-columns(4);
		// 		@include shift(1);
		// 		top:0px;
		// 		margin-top:0px;
		// 		opacity:1;
		// 		@include transform(translateY(0px));
		// 	}
		// }
	}
}

@include media($mobile) {
	.project_pool {
		padding: 65px 5% 40px 5%;

		.project-container {
			padding: 0;
			width: 100%;
			float: none;
			margin-bottom: $bodySizeLH;
		}

		// .tile {

		// 	&.tile-0 ,&.tile-1 ,&.tile-2 ,&.tile-3 ,&.tile-4 {
		// 		@include span-columns(4);
		// 		@include shift(0);
		// 		top:0px;
		// 		margin-top:0px;
		// 		opacity:1;
		// 		@include transform(translateY(0px));
		// 	}
		// }
	}
}