.closer {
	position:fixed;
	top:30px;
	right:30px;
	color:$white;
	font-size:$bodySize;
	line-height:$bodySizeLH;
	z-index:25000;
}

$slideTrans : .3s $ease-in-out-cubic;

.single_project {
	position:fixed;
	top:0px;
	z-index:20000;
	background-color:$black;
	color:$white;
	width:100%;
	height:100%;
	background-size:cover;
	margin-left: -30px;
}

.single_project_bg {
	position: absolute;
	width:100%;
	height:100%;
	z-index:0;
	background-size: cover;
	background-position: center;
	opacity: 0;
	@include transition(opacity .5s);

	&.loaded {
		opacity: 1;
	}

	&:before {
		content:"";
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.85);
	}
}

.single_project_slider {
	position:absolute;
	width:100%;
	height:100%;
	z-index:1;

	.slick-dots {
		position:absolute;
		width:400px;
		left:50%;
		margin-left:-200px;
		bottom:-100px;
		text-align:center;
		@include transition(bottom $slideTrans);

		li {
			display:inline-block;
			margin:0 5px;

			button {
				border-radius:100%;
				border:none;
				background-color:$grey;
				text-indent:-9999px;
				width:8px;
				height:8px;
				text-align:left;
				display:block;
				padding:0px;
				cursor:pointer;
				@include transition(background-color .4s);
			}

			&.slick-active {
				button {
					background-color:$white;
				}
			}
		}
	}

	.slick-arrow {
		position:absolute;
		bottom:-100px;
		border:none;
		background-color:transparent;
		@include transition(bottom $slideTrans,left .1s, right .1s);
		z-index:2000;
		cursor:pointer;
		font-size: 0px;

		&:before,&:after {
			color:$white;
			font-size:$subheadlineSize;
			text-indent:0px;
		}

		&.slick-prev {
			left:30px;

			&:hover {
				left:25px;
			}

			&:before {
				content:"\2190";
			}
		}
		&.slick-next {
			right:30px;

			&:hover {
				right:25px;
			}

			&:after {
				content:"\2192";
			}
		}
	}

	.slick-list, .slick-track, .slick-slide {
		height:100%;
	}

	.slide {
		position:relative;

		figure {
			position:absolute;
			height:60%;
			width:60%;
			background-repeat:no-repeat;
			background-position:center;
			background-size:contain;
			left:50%;
			top:50%;
			@include transform(translate3d(-50%,-50%,0));

			&.image {
				@include transition(width .3s, height .3s, background-color .3s);
			}

			iframe {
				width:100% !important;
				height:100% !important;
			}

			.fullscreen_init {
				font-size: $metaSize;
				line-height: $metaSizeLH;
				color: $white;
				position: absolute;
				width: 150px;
				margin-left: -75px;
				left: 50%;
				bottom: 0px;
				text-align: center;
				opacity: 0;
				@include transition(opacity .2s, bottom .2s);
				outline: none;
			}

			&:hover {
				.fullscreen_init {
					opacity: 1;
				}
			}

			&.fullscreen {
				height: 100%;
				width: 100%;
				background-color: $black;

				.fullscreen_init {
					bottom: 60px;
				}
			}
		}

		.caption {
			position:absolute;
			bottom:-100px;
			font-size:$bodySize;
			line-height:$bodySizeLH;
			text-align:center;
			width:500px;
			margin-left:-250px;
			left:50%;
			@include transition(bottom $slideTrans);
		}
	}

	&.trans {
		.slick-dots {
			bottom:29px;
		}
		.slick-arrow {
			bottom:20px;
		}
		.slide .caption {
			bottom:60px;
		}
	}
}

.play_wrapper {
	position:absolute;
	display: table;
	width:100%;
	height:100%;
	background-color: $black;
	border: 1px solid $white;
	z-index:2000;
	@include transition(opacity .3s, background-color .3s, visibility .3s);
	visibility: visible;

	.play_content {
		display: table-cell;
		vertical-align: middle;
		padding: 0 20%;
		text-align: center;
	}

	.play_btn {
		color:$white;
		opacity:1;
		outline:none;
		text-align:center;
		display: block;
		margin-top:$bodySizeLH;
	}

	&:hover {
		opacity:1;
	}
}
.state-play {
	.play_wrapper {
		background-color: transparent;
		opacity:0;
		visibility: hidden;
	}
}


.single_project {

	.project_body {

	}

	.project_credits {
		font-size: $bodySize;
		line-height: $bodySizeLH;
		margin-top: 40px;

		u, i, b, strong, em, span {
			text-decoration: none !important;
			font-weight: normal !important;
			color: $grey;
		}
	}
}


@include media($tablet) {
	

	@include media(orientation landscape) {
		.closer {
			display:none;
		}

		.single_project {
			.slick-arrow {
				display:none !important;
			}
			&.trans {

				.slide {

					.caption {
						display:none;
					}

					figure {
						height:90%;
						width:90%;
					}

					.cell {
						display:block;
						vertical-align:initial;
						height:90vh;
						overflow:auto;
						margin-top:5vh;
					}
				}
			}
			.slick-dots {
				display:none !important;
			}
		}
	}
}

@include media($mobile) {
	.single_project {

		margin-left: -5%;

		.slide {

			figure {
				position:absolute;
				height:85%;
				width:85%;
				background-repeat:no-repeat;
				background-position:center;
				background-size:contain;
				left:50%;
				top:50%;
				@include transform(translate3d(-50%,-50%,0));
			}
		}
	}
}