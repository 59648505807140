#home_text {
	position:fixed;
	top:0px;
	left:0px;
	background-color:$black;
	color:$white;
	width:100%;
	height:100%;
	z-index:15000;

	@extend .table;

	.cell {
		text-align:center;

		h2 {
			width:500px;
			margin:0 auto;
		}
	}
}

.home_slider {
	height:100%;

	.slick-list, .slick-track, .slick-slide {
		height:100%;
	}

	.slick-slide {
		background-size:cover;
		background-position:center;
	}

	.caption {
		color:$white;
		font-size:$metaSize;
		line-height:$metaSizeLH;
		@include transform(rotate(-90deg));
		@include transform-origin(bottom left);
		position:absolute;
		width:50%;
		padding:20px;
		bottom:0px;
		left:100%;
	}

	.bg {
		width:100%;
		height:100%;
		position:absolute;
		z-index:-1;
		opacity:0;
		@include transition(opacity .2s);
		background-size:cover;
		background-position:center;

		&.show {
			opacity:1;
		}
	}
}



@include media($tablet) {
	
}

@include media($mobile) {
	#home_text {

		.cell {

			h2 {
				width:90%;
			}
		}
	}
}