@import "bourbon";
@import "neat";
@import "libs/reset";
@import "libs/ie";
@import "libs/slick";

/*

font-face("source-sans-pro", "/font/source-sans-pro/source-sans-pro-regular");

*/

$max-width:90%;

$desktop: new-breakpoint(min-width 769px 12);
$small: new-breakpoint(max-width 1100px 6);
$tablet: new-breakpoint(max-width 768px 6);
$mobile: new-breakpoint(max-width 480px 4);


/*
=================================================

									MIXINS

=================================================
*/

@mixin jtContainer
{
	@include outer-container;
	width:$max-width;
	max-width:1280px;
	position:relative;
}


/*
=================================================

									TYPOGRAPHY

=================================================
*/

	$headlineSize : 42px;
		$headlineSizeLH : 44px;

	$subheadlineSize : 32px;
		$subheadlineSizeLH : 34px;

	$midSize : 32px;
		$midSizeLH : 36px;

	$mobileMidSize : 26px;
		$mobileMidSizeLH : 28px;

	$bodySize : 18px;
		$bodySizeLH : 21px;

	$metaSize : 15px;
		$metaSizeLH : 18px;


	@mixin headlineFont()
	{
		font-family:'HelveticaNeue-CondensedBold',sans-serif;
		font-weight:normal;
		font-style:normal;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-transform:uppercase;
	}

	@mixin bodyFont()
	{
		font-family:'HelveticaNeue-CondensedBold',sans-serif;
		font-weight:normal;
		font-style:normal;
		-webkit-font-smoothing:antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-transform:uppercase;
	}



/*
=================================================

									COLOURS

=================================================
*/

	$black : #000;
	$white : #FFFFFF;
	$grey  : lighten($black,50);

	.black {
		color:$black;
	}
		.black-bg {
			color:$black;
		}

	.white {
		color:$white;
	}
		.white-bg {
			color:$white;
		}

	.grey {
		color:$white;
	}
		.grey-bg {
			color:$grey;
		}



/*
=================================================

									GENERAL STYLES

=================================================
*/

	html, body {
		@include bodyFont();
		font-size:$midSize;
		line-height:$midSizeLH;
		height:100%;
		color:$white;
		background-color:$black;

		@include media($small) {
			font-size:$mobileMidSize;
			line-height:$mobileMidSizeLH;
		}

		@include media(max-height 768px) {
			font-size:$mobileMidSize;
			line-height:$mobileMidSizeLH;
		}

		@include media($mobile) {
			font-size:$bodySize;
			line-height:$bodySizeLH;
		}
	}

	html {
		&.mobile-open {

			@include media($tablet) {
				overflow:hidden;
			}
		}
	}

	.overflow {
		overflow:hidden;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight:normal;
	}
	h1 {
		@include headlineFont();
		font-size:$headlineSize;
		line-height:$headlineSizeLH;
	}
	h2 {
		@include headlineFont();
		font-size:$subheadlineSize;
		line-height:$subheadlineSizeLH;
	}
	h3 {
		@include headlineFont();
		font-size:$bodySize;
		line-height:$bodySizeLH;
	}

	.left-align {
		text-align:left;
	}
	.centre-align {
		text-align:center;
	}
	.right-align {
		text-align:right;
	}

	a {
		text-decoration:none;
		opacity:1;
		@include transition(opacity .2s);

		&:hover {
			opacity:0.6;
		}
	}

	img {
		max-width:100%;
		height:auto;
	}

	.clearfix {
		@include clearfix;
	}
	.container {
		@include jtContainer;
	}



/*
======================================

	EXTEND STYLES

======================================
*/

	.vertical-align-middle {
		position:absolute;
		top:50%;
	}

	.table {
		display:table;

		.cell {
			display:table-cell;
			vertical-align:middle;
		}

		.mobile-cell {
			display:block;
			margin-top:80px;
		}

		
	}

	.fullscreen {
		width:100%;
		height: 95%;
	    top: 5%;
	    position: absolute;

		@include media($mobile) {
			overflow:auto;
			max-height:100%;
			max-width:100%;
			position:absolute;
		}
	}

	.central_content {
		@include span-columns(6);
		text-align:center;
		@include shift(3);

		h3 {
			margin-bottom:$bodySizeLH / 2;
		}
		p {
			margin-bottom:$midSizeLH;

			&:last-of-type {
				margin-bottom:0px;
			}
		}

		a {
			color:$white;
		}

		@include media($small) {
			@include span-columns(4);
			@include shift(1);
		}
		@include media($mobile) {
			@include span-columns(4);
			@include shift(0);
		}
	}

/*
======================================

	PARTIALS

======================================
*/

	@import "partials/nav";
	@import "partials/home";
	@import "partials/projects";
	@import "partials/single";
	@import "partials/profile";








