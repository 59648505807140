
nav#main-head {
	position:fixed;
	width:100%;
	@include transition-property(transform, opacity, background-color);
	@include transition-duration(.25s);
	background-color:$black;
	z-index:10000;

	& > .container {
		padding:20px 0px;
	}

	/* 
	// headroom.js class handles
	*/

	&.headroom--top {

	}
	&.headroom--not-top {

	}
	&.headroom--unpinned {
		@include transform(translateY(-120%));
	}

	.logo_wrap {
		position:absolute;
		width:100px;
		height:66px;
		top:-5px;
	}

	.primary-nav {
		@include span-columns(8);
		float:none;
		position:relative;
		z-index:2000;
		top:8px;
		margin-left:auto !important;
		margin-right:auto !important;
		text-align:center;
	    padding-bottom: 18px;

		li {
			display:inline-block;
			margin-left:10px;

			a {
				color:$grey;
				font-size:$metaSize;
				line-height:$metaSizeLH;
				@include transition(color .2s);

				&:hover {
					opacity:1;
				}

				&.active {
					color:$black;
					color:$white;
				}
			}

			&.logo-list {
				width:100px;
				height:20px;
			}
		}
	}

	&.white .primary-nav li a.active {
		color:$white;
	}

	&.white {
		background-color:transparent;
	}

	.navicon {
		display:none;
	}
}

.logo {
	color:$black;
	text-indent:-9999px;
	display:inline-block;
	width:100px;
	height:66px;
	background-image:url(../images/logo-black.png);
	background-image:url(../images/logo-white.png);
	background-size:contain;
	opacity:1;
	position:absolute;
	@include transition(opacity .2s);
	left:0px;

	&.white {
		background-image:url(../images/logo-white.png);
		opacity:0;
	}
	/*
	nav.white & {

		&.black {
			opacity:0;
		}
		&.white {
			opacity:1;
		}
	}
	*/
}

@include media($desktop) {
	#mobile-menu {
		display:none !important;
	}
	.mobile_logo {
		display:none;
	}
}

@include media($tablet) {
	nav#main-head {
		&> .container {
			padding:30px 0px;
		}

		&.headroom--unpinned {
			@include transform(translateY(-150%));
		}

		.mobile_logo {
			display:block;
			height:54px;
			width:80px;
			top:5px;

			.logo {
				width:100%;
				height:100%;
			}
		}

		.primary-nav {
			display:none;
		}

		.navicon {
			display:block;
			width:32px;
			height:32px;
			position:absolute;
			right:0px;
			top:50%;
			margin-top:-19px;
			z-index:1000;

			.line {
				background-color:$white;
				position:absolute;
				height:2px;
				width:100%;
				@include transition( .25s transform, opacity .25s);

				&.one {
					top:15%;
				}

				&.two {
					top:15px;
				}

				&.three {
					bottom:15%;
				}
			}

			&:hover {
				opacity:1;
			}

			&.close, html.mobile-open &  {

				.line {

					&.one {
						@include transform(rotate(45deg));
						top:15px;
					}
					&.two {
						opacity:0;
					}
					&.three {
						@include transform(rotate(-45deg));
						bottom:15px;
					}
				}
			}
		}

		html.mobile-open & {
			@include transform(translateY(0%) !important);
		}
	}

	#mobile-menu {
		position:fixed;
		width:100%;
		height:100%;
		display:none;
		z-index:8000;
		background-color:$black;

		.container {
			height:100%;
			display:table;
		}

		.primary-nav {
			display:table-cell;
			vertical-align:middle;

			li {
				margin-bottom:$bodySizeLH;
				text-align:center;

				a {
					color:$white;
					@extend p;
				}
			}
		}
	}
}

@include media($mobile) {

	#mobile-menu {

		.primary-nav {

			li {

				a {
					@extend h2;
				}
			}
		}
	}
}