

.profile-page {
	padding-top:140px;
	text-align:left;

	.table {
		height:calc(100vh - 240px);
		display:table;

		.cell {
		}
	}
}

.biography {
	margin-bottom:40px;
	
	li {
		display:inline-block;
		margin:0 10px;

		&:nth-child(1) {
			text-align:right;
		}
		&:nth-child(2) {
			text-align:left;
		}

		a {
			color:$grey;

			&.active {
				color:$white;
			}
		}
	}
}

.team_member {
	display:none;
	padding-bottom:100px;

	.image-container {
		position: relative;
	}
	.team-image {
		position: absolute;
		@include span-columns(4);

		@include media($tablet) {
			position: relative;
		}
	}

	&.sticky {
		.image-container {
			position: fixed;
			top: 10px;
    	left: 5%;

			@include media(min-width 1440px) {
				margin-left: -640px;
				left:50%;
			}
		}

		.team-image {

		}
	}

	.team-information {
		@include span-columns(8);
		@include shift(4);
	}

	.fields {
		@include row;

		li {
			@extend h3;
			margin-bottom:6px;
			@include row;

			&.title {
				margin-bottom:$bodySizeLH;
				margin-top:60px;
			}
			.field-1, .field-2 {
				@include span-columns(3);
				min-height:1px;
			}
			.field-3 {
				@include span-columns(6);
				min-height:1px;
			}
		}

		.year {
			border-bottom: 1px solid $white;
			margin-bottom: $bodySizeLH;
			margin-top:50px;

			h3 {
				font-size: 82px;
				line-height: 82px;
			}
		}
	}

	.education-fields {
		margin-top:50px;
	}

	.team-links {

		li {
			display: inline-block;
			font-size: $bodySize;
			line-height: $bodySizeLH;

			&:not(.team-links-title):after {
				content:"/";
				margin-right: 5px;
				margin-left: 5px;
			}

			&:last-of-type:after {
				content:"/";
				display: none;

			}

			a {
				color: $white;

				@include transition(color .2s);

				&.active {
					color: $grey;
				}
				&:hover {
					opacity: 1;
					color: $grey;
				}
			}
		}

		.team-links-title {
			display:block;
			font-size:$metaSize;
			line-height: $metaSizeLH;
		}
	}
	.team-field-group {
		min-height: 400px;
	}
}

@include media($tablet) {

	.team_member {

		.team-image {
			@include span-columns(2);
			margin-bottom: $bodySizeLH;
		}
		.team-information {
			@include span-columns(6);
			@include shift(0);
			li {

			}
		}

		.fields {

			li {

				&.title {

				}

				.year {
					@include span-columns(6);

					&.heading {
						border-bottom:1px solid $white;
						margin-bottom:10px;
					}
				}
				.field-1, .field-2 {

				}
				.field-3 {

				}
			}
		}
	}

	.biography {
		li {
			&:nth-child(1),&:nth-child(2) {
				text-align:center;
			}
		}
	}
}

@include media($mobile) {

	.team_member {

		.team-image {
			display:none;
		}
		.team-information {
			@include span-columns(4);
			li {

			}
		}

		.fields {

			li {
				width: calc(100% + 32px);
				margin-left: -16px;
				padding: 0 16px;

				&.mob-0 {
						background-color:rgba(255,255,255,0.15);
				}
				.field-1 {
					padding-top:10px;
				}
				.field-3 {
					padding-bottom:10px;
				}

				&.title {

				}

				&.year {
					width: 100%;
					margin-left: 0px;
					padding: 0px;
				}
				.field-1, .field-2, .field-3 {
					@include span-columns(4);
					margin-right:0;
				}
			}
		}
	}
}

